import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getRecords,
  getDicomFile,
  getRecordType,
  getStudyList,
  getFilledForms,
  getRecipientList,
  getDoctorContactDetails,
  postReportToDoctorAndLab,
  getStudyDetail,
  getReportUrl,
  getThumbnail,
  udpateStatusRestrictedToAvailable,
  getScanDetails,
  postReportToDoctorAndAnyone,
  getViewerUrl,
  getAttachedReports,
} from "../../api";
import { setShowStatus, unsetShowStatus } from "../slice/status-slice";

export const getRecordTypeAction = createAsyncThunk(
  "get-record-type",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: `Fetching record type`,
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getRecordType(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching record type",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while fetching record type");
    }
  }
);

export const getRecordsAction = createAsyncThunk(
  "get-records",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: `Fetching records`,
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getRecords(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || `Error while fetching ${data.record_type}`,
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || `Error while fetching ${data.record_type}`);
    }
  }
);

export const getDicomFileAction = createAsyncThunk(
  "get-dicom-file",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Fetching dicom file",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getDicomFile(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching dicom file",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while fetching dicom file"
      );
    }
  }
);

export const getStudyListAction = createAsyncThunk(
  "get-study-list",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Fetching study list",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getStudyList(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching study list",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while fetching study list"
      );
    }
  }
);

export const getFilledFormsAction = createAsyncThunk(
  "get-filled-forms",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Fetching filled forms",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getFilledForms(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching filled forms",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while fetching filled forms"
      );
    }
  }
);

export const getRecipientListAction = createAsyncThunk(
  "get_recipient_list",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Fetching Recipient list",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getRecipientList(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching Recipient list",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while fetching Recipient list"
      );
    }
  }
);

export const getDoctorContactList = createAsyncThunk(
  "get_doctor_contact_detail",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Fetching doctor list",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getDoctorContactDetails(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching doctor list",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while fetching doctor list"
      );
    }
  }
);

export const sendReportToDoctorOrLab = createAsyncThunk(
  "POST_DOCTOR_LAB_REPORT",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "sharing report",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await postReportToDoctorAndLab(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while sharing report",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while sharing report"
      );
    }
  }
);

export const getStudyInfo = createAsyncThunk(
  "get_study_detail_page_info",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Fetching study detail",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getStudyDetail(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching study details",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while fetching study details"
      );
    }
  }
);

export const changeStatusRestrictedToAvailableAction = createAsyncThunk(
  "update_restricted_to_available",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Update status restricted to available",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await udpateStatusRestrictedToAvailable(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while Update status restricted to available",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while Update status restricted to available"
      );
    }
  }
);

export const getReportURL = createAsyncThunk(
  "get_report_url",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Fetching report url",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getReportUrl(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while get report url",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while get report url"
      );
    }
  }
);

export const getThumbnails = createAsyncThunk(
  "get_thumbnail_image",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Fetching thumbnail",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getThumbnail(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while get thumbnail",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while get thumbnail"
      );
    }
  }
);

export const getScansDetails = createAsyncThunk(
  "get_scans_info",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Fetching scans detail",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getScanDetails(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching scans details",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while fetching scans details"
      );
    }
  }
);

export const sendReportToDoctorToAnyone = createAsyncThunk(
  "POST_DOCTOR_ANYONE_REPORT",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "sharing report",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await postReportToDoctorAndAnyone(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while sharing report",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while sharing report"
      );
    }
  }
);

export const getViewerUrls = createAsyncThunk(
  "VIEW_URLS",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "sharing report",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getViewerUrl(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while sharing report",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while sharing report"
      );
    }
  }
);


export const getAttachedReport = createAsyncThunk(
  "get_attached_report_info",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Fetching attached report",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getAttachedReports(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching attached report",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while Fetching attached report"
      );
    }
  }
);