import { Box, FormControl, MenuItem, Select } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const StatusDropdown = ({
  handleChange,
  selectedStatus,
  dropdownOptions,
  disabled = false,
}) => {
  return (
    <>
      <FormControl>
        <Select
          value={selectedStatus}
          onChange={handleChange}
          disabled={disabled}
          IconComponent={KeyboardArrowDownIcon}
          sx={{
            paddingRight: 1,
            paddingTop: 0,
            height: "45px",
            color: selectedStatus !== "RESTRICTED" ? "#7ABA56" : "#CC8552",
            "& .MuiOutlinedInput-notchedOutline": {
              border: `2px solid  ${
                selectedStatus !== "RESTRICTED" ? "#7ABA56" : "#CC8552"
              }`,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: selectedStatus !== 'RESTRICTED' ? '#7ABA56' : '#CC8552',
            },
            "& .MuiSvgIcon-root": {
              background:
                selectedStatus !== "RESTRICTED" ? "#7ABA56" : "#CC8552",
              borderRadius: 1,
              color: "#FFF",
            },
          }}
        >
          {dropdownOptions.map((option) => (
            <MenuItem key={`dropdown-${option.value}`} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <Box sx={{ whiteSpace: "normal", fontSize: "11px" }}>
          {selectedStatus !== "RESTRICTED"
            ? "(File will be available for the patient and others to view)"
            : "(Only available for clinicians or radiologists to view)"}
        </Box>
      </FormControl>
    </>
  );
};

export default StatusDropdown;
