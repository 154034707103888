import React,  { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IBackArrow } from "../../../assets";
import {
    Container,
    TopHeader,
    ArrowBackImage,
    RecordsContainer,
    RecordsHead,
    AcceptedFormat, Section, UploadBox, UploadBoxWrapper, FileUploadBox, CrossIcon,
    InnerUploadContainer, PdfText, FileName, DragText, AddButton, UploadBoxIcon
  } from "./style.components";
import {
    IAddIconLarge,
    IFileIcon,
    IUploadIconBig
} from "../../../assets";
import { Loader } from "../../../../src/components/shared";
import { patientSelector } from "../../../store/slice/patient.slice";
import { uploadRecordsAction, sendRecordsAction, getRecordsAction } from "../../../store/actions";
import { Select, MenuItem, FormControl, InputLabel, Box } from "@mui/material";
import { SnackBar } from "../../../components/shared";
import StatusDropdown from "../../../components/shared/StatusDropdown";
import { resetThumbnail } from "../../../store/slice/thumbanils.slice"; 

const dropdownOptions = [
    { label: "Available", value: "AVAILABLE" },
    { label: "Restricted", value: "RESTRICTED" },
];

const AddRecords = () => {
    const fileInputRef = useRef(null);
    const { selectedPatientId } = useSelector(patientSelector);
    const [isUploadDisabled, setIsUploadDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRecordType, setSelectedRecordType] = useState('');
    const [recordsArray, setRecordsArray] = useState([]);
    const currentRecordType = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setIsUploadDisabled(recordsArray.length === 0 || recordsArray.some(record => record.recordType === ''));
    }, [recordsArray]);
    
    const handleChange = (event, fileId) => {
        const index = recordsArray.findIndex((item) => item.file_id === fileId);
        if (index !== -1) {
          const updatedItem = {
            ...recordsArray[index],
            status: event.target.value,
          };
          const updatedArray = [
            ...recordsArray.slice(0, index),
            updatedItem,
            ...recordsArray.slice(index + 1),
          ];
          setRecordsArray
          ([...updatedArray]);
        }
      };
    

    const handleDragOver = (event) => {
        event.preventDefault();
    };
    
    const uploadFile = async (file) => {
        setIsLoading(true);
        var formData = new FormData();
        formData.append("files", file);
        try {
            const uploadResponse = await dispatch(uploadRecordsAction(formData));
            const response = uploadResponse?.payload;
            setIsLoading(false);
            if (response?.status === "success") {
                const transformedResponse = response.result.map(item => ({
                    ...item,
                    recordType: '',
                    status: "AVAILABLE",
                }));
                setRecordsArray(prev => [...prev, ...transformedResponse]);
            } else {
                alert("Something went wrong!");
            }
        } catch (error) {
            setIsLoading(false);
            console.error("Error calling upload records:", error);
        }
    };
    
           
    const openFilePicker = () => {
        currentRecordType.current = selectedRecordType;
        if (fileInputRef.current) {
          fileInputRef.current.click();
        }
    };

    const handleFileSelect = (event) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const newFiles = Array.from(files).map(file => ({
                file,
                recordType: '',
                file_id: Math.random().toString(36).substr(2, 9),
                file_name: file.name,
                status: "AVAILABLE",
            }));
            setRecordsArray(prev => [...prev, ...newFiles]);
        }
        event.target.value = null;
    };
    
    
    const handleDrop = (event) => {
        event.preventDefault();
        if (event.dataTransfer.items) {
            for (let i = 0; i < event.dataTransfer.items.length; i++) {
                const fileItem = event.dataTransfer.items[i];
                if (fileItem.kind === 'file') {
                    const file = fileItem.getAsFile();
                    if (file) {
                        uploadFile(file);
                    }
                }
            }
        }
    };

    const handleSend = async () => {
        if (isUploadDisabled) return;
        setIsLoading(true);
        setIsUploadDisabled(true);
        try {
            // Step 1: Collect promises for upload operations of each record type
            const uploadPromises = recordsArray.map(async (record) => {
                const formData = new FormData();
                formData.append("files", record.file);
                formData.append("file_type", record.recordType);

                // Attempt to upload the file
                const uploadResponse = await dispatch(uploadRecordsAction(formData)).unwrap();
                if (uploadResponse?.status !== "success") {
                    throw new Error("Upload failed for one or more files");
                }
                const transformedUploadResponse = uploadResponse.result.map((item) => ({...item, status: record.status}));
                return { ...uploadResponse, result: transformedUploadResponse, recordType: record.recordType }; // Include recordType in response for later use
            });
    
            // Wait for all uploads to complete
            const uploadResults = await Promise.all(uploadPromises);
    
            // Step 2: Send the uploaded file info for each record type
            // Group upload results by recordType
            const groupedResults = uploadResults.reduce((acc, item) => {
                const { recordType, result } = item;
                if (!acc[recordType]) {
                    acc[recordType] = [];
                }
                acc[recordType].push(...result); // Assuming result is an array of file info
                return acc;
            }, {});
            // Create promises for each send operation
            const sendPromises = Object.entries(groupedResults).map(async ([recordType, files]) => {
                const payload = {
                    patient_id: selectedPatientId,
                    report_info: files.map(file => ({
                        file_type: file.file_type,
                        mimetype: file.mimetype,
                        file_id: file.file_id,
                        file_name: file.file_name,
                        file_size: file.file_size,
                        s3_file_url: file.s3_file_url,
                        status: file.status,
                    }))
                };
                // Send records for the current record type
                return dispatch(sendRecordsAction({ type: recordType, payload }));
            });
    
            // Wait for all send operations to complete
            await Promise.all(sendPromises);
            const callUpdatedData =  Object.entries(groupedResults).map(async ([recordType]) => (
                 dispatch(
                    getRecordsAction({
                    record_type: recordType,
                    patient_id: selectedPatientId,
                    limit: 5,
                    })
                )
            ));
            dispatch(resetThumbnail());
            await Promise.all(callUpdatedData);

            setTimeout(() => {
                navigate(`/patient-records?patient_id=${selectedPatientId}`);
            }, 300);
        } catch (error) {
            console.error("Error processing send records:", error);
        } finally {
            setIsLoading(false);
            setIsUploadDisabled(false);
        }
    };    
    
    return (
        <Container>
            <TopHeader>
                <ArrowBackImage src={IBackArrow} alt="back-arrow" 
                onClick={() => {
                    navigate(-1);
                }} 
                />
                Add Records
            </TopHeader>
            <RecordsContainer>
                <RecordsHead>Upload Records</RecordsHead>
                <AcceptedFormat>Accepted formats are PDF, JPG & PNG with less than 5 MB.</AcceptedFormat>
                <Section>
                    <input 
                        type="file" 
                        style={{ display: 'none' }} 
                        ref={fileInputRef}
                        onChange={handleFileSelect}
                        multiple
                        accept=".pdf, .jpg, .jpeg, .png"
                    />
                    <UploadBoxWrapper>
                        {isLoading ? (
                            <Loader />
                        ) : (
                        recordsArray.length > 0 ? (
                            recordsArray.map((row, index) => (
                                <div key={row.file_id} style={{ width: 215}}>
                                    <FileUploadBox>
                                        <CrossIcon onClick={() => {
                                            setRecordsArray(prevArray => prevArray.filter(item => item.file_id !== row.file_id));
                                        }}>X</CrossIcon>
                                        <InnerUploadContainer>
                                            <img src={IFileIcon} alt="File Icon" />
                                            <PdfText>PDF</PdfText>
                                        </InnerUploadContainer>
                                        <FileName>{row.file_name}</FileName>
                                    </FileUploadBox>
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel id={`record-type-select-label-${index}`}>Record Type</InputLabel>
                                        <Select
                                            labelId={`record-type-select-label-${index}`}
                                            id={`record-type-select-${index}`}
                                            value={row.recordType}
                                            label="Record Type"
                                            onChange={(e) => {
                                                const newRecordsArray = [...recordsArray];
                                                newRecordsArray[index].recordType = e.target.value;
                                                setRecordsArray(newRecordsArray);
                                                setSelectedRecordType(e.target.value)
                                            }}
                                        >
                                            <MenuItem value=""><em>Select Record type</em></MenuItem>
                                            <MenuItem value="medical_letter">Medical Letter</MenuItem>
                                            <MenuItem value="prescription">Prescription</MenuItem>
                                            <MenuItem value="report">Report</MenuItem>
                                        </Select>

                                    </FormControl>
                                    {row?.recordType === 'report' && <Box mt={2} mb={2}>
                                        <StatusDropdown
                                            selectedStatus={row?.status}
                                            handleChange={(e) => handleChange(e, row.file_id)}
                                            dropdownOptions={dropdownOptions}
                                        />
                                    </Box>}
                                </div>
                            )
                        )) : null
                           
                            // <UploadBoxIcon onClick={openFilePicker}>
                            //     <img src={IUploadIconBig} alt="Add Icon" />
                            //     <DragText>Select files to add</DragText>
                            // </UploadBoxIcon>
                        // )
                    )}
                    {
                        !isLoading &&
                        <UploadBox onClick={openFilePicker} onDrop={handleDrop} onDragOver={handleDragOver} draggable>
                            <img src={IAddIconLarge} alt="Add Icon" />
                            <DragText>Select files to add</DragText>
                        </UploadBox>
                    }
                    </UploadBoxWrapper>
                </Section>
                <AddButton 
                    onClick={handleSend} 
                    disabled={isUploadDisabled}
                >
                    Send
                </AddButton>
                <SnackBar />
            </RecordsContainer>
        </Container>
    );
}

export default AddRecords;